.dlx-cookieInfo {
	@include ease(opacity visibility, $durationShort, $easeBase);
	background-color: $colorSecond;
	bottom: 0;
	color: $colorBaseInverted;
	left: 0;
	position: fixed;
	visibility: hidden;
	opacity: 0;
	width: 100%;
	z-index: 200;

	&.dlx-js-visible {
		@include fadeIn($durationBase, $easeBase);
	}

	&.dlx-js-hidden {
		@include fadeOut($durationBase, $easeBase);
	}

	&__container {
		@include dlx-container();
		margin-bottom: $base4;
		margin-top: $base3;

		@include media($xLargeLayout) {
			margin-bottom: $base5;
			margin-top: $base3;
		}
	}

	&__content {
		@include dlx-mainContent();
		@include staticColumns(0);
	}

	&__contentText {
		width: 100%;

		@include media($xLargeLayout) {
			width: staticColumnSize(4, 6, $gutterXLarge);
		}
	}

	&__contentButton {
		width: 100%;
		margin-top: $base3;

		@include media($xLargeLayout) {
			margin-top: 0;
			width: staticColumnSize(1, 6, $gutterXLarge);
		}
	}

	&__link {
		color: inherit;
		text-decoration: none;
		text-transform: uppercase;
		display: block;
		margin-top: $base2;
	}

	&__title {
		@include font(base);
	}

	&__text {
		@include font(subheader);
		//
	}

	&__close {
		//
	}
}
