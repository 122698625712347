.dlx-block {
	@include dlx-container();

	position: relative;

	//margin-top: $base1;

	@include media($smallLayout) {
		//margin-top: $base1;
	}

	@include media($mediumLayout) {
		//margin-top: $base2;
	}

	@include media($xLargeLayout) {
		//margin-top: $base2;
	}

	&--full {
		@include dlx-undoContainer();
	}

	&--noOveflow {
		overflow: hidden;
	}

	&--overflowHidden {
		overflow: hidden;
	}

	&--noTopMargin {
		margin-top: -$base8;
	}
}
