.dlx-main {
	a {
		color: inherit;
		text-decoration: none;
	}

	&.dlx-js-covered {
		position: fixed;
		width: 100%;
		left: 0;
	}

	.dlx-body.dlx-js-homePage & {
		//overflow: hidden;
		//max-height: 100vh;
	}
}
