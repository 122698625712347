.dlx-body {
	@include font(base);
	color: $colorBase;
	font-family: $fontFamilyMain;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	//just as a test
	// TODO: please check carefully different browsers
	//if it helps with overall text readability
	-webkit-font-smoothing: antialiased;

	&.dlx-js-homePage {
		overflow-x: hidden;
		overflow-y: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 1;
		touch-action: none;
	}


	&__loader {
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 10;
	}
}
