.dlx-loader {
	@include fadeIn($durationBase, $easeBase);
	background-color: $colorBgBase;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	z-index: 100;

	&.dlx-js-hidden {
		@include fadeOut($durationLoader, $easeBase, $durationBase);
	}

	&__dot {
		@include ease(transform opacity, $durationBase, $easeBase);
		align-items: center;
		color: $colorBase;
		display: flex;
		flex-direction: row;
		text-decoration: none;
		position: relative;
		height: $mainPageDotSmall;
		width: $mainPageDotSmall;

		.dlx-loader.dlx-js-hidden & {
			transform: scale(0);
			opacity: 0;
		}

		@include media($smallLayout) {
			height: $mainPageDotMedium;
			width: $mainPageDotMedium;
		}

		@include media($xLargeLayout) {
			height: $mainPageDotLarge;
			width: $mainPageDotLarge;
		}
	}


	&__dotShape {
		@include ease(transform, $durationBase, $easeOutCubic);
		animation-delay: $durationVeryLong;
		animation-duration: $durationVeryLong * 3;
		animation-iteration-count: infinite;
		animation-name: loader-animation;
		background-color: $colorLoader;
		border-radius: 50%;
		display: block;
		height: 100%;
		transform: scale(1);
		width: 100%;
	}
}
