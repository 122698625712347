@use "sass:math";

$htmlFontSize: 62.5%;

$fontSizes: (
	base: (
		font-size: pxToRem(18),
		line-height: math.div(24, 18),
		font-weight: normal,
		variants: (
			$smallLayout: (
				font-size: pxToRem(20),
				line-height: (28 * 0.05)
			),
			$xLargeLayout: (
				font-size: pxToRem(24),
				line-height: math.div(34, 24)
			)
		)
	),
	intro: (
		font-size: pxToRem(17),
		line-height: math.div(20, 17),
		font-weight: normal,
		variants: (
			$smallLayout: (
				font-size: pxToRem(18),
				line-height: math.div(22, 18)
			),
			$xLargeLayout: (
				font-size: pxToRem(20),
				line-height: (24 * 0.05)
			)
		)
	),
	largehead: (
		font-family: $fontFamilyMain,
		font-style: normal,
		font-size: pxToRem(40),
		font-weight: 500,
		line-height: (45 * 0.025),
		variants: (
			$smallLayout: (
				font-size: pxToRem(60),
				line-height: math.div(65, 60)
			),
			$xLargeLayout: (
				font-size: pxToRem(100),
				line-height: (110 * 0.01)
			)
		)
	),
	largeheadlight: (
		font-family: $fontFamilyMain,
		font-style: normal,
		font-size: pxToRem(40),
		font-weight: 200,
		line-height: (45 * 0.025),
		variants: (
			$smallLayout: (
				font-size: pxToRem(60),
				line-height: math.div(65, 60)
			),
			$xLargeLayout: (
				font-size: pxToRem(100),
				line-height: (110 * 0.01)
			)
		)
	),
	bannerHead: (
		font-family: $fontFamilyMain,
		font-style: normal,
		font-size: pxToRem(40),
		line-height: (45 * 0.025),
		variants: (
			$smallLayout: (
				font-size: pxToRem(50),
				line-height: (60 * 0.02)
			),
			$xLargeLayout: (
				font-size: pxToRem(60),
				line-height: math.div(70, 60)
			)
		)
	),
	head: (
		font-family: $fontFamilyMain,
		font-style: normal,
		font-size: pxToRem(30),
		font-weight: 500,
		line-height: math.div(35, 30),
		variants: (
			$smallLayout: (
				font-size: pxToRem(45),
				line-height: math.div(55, 45)
			),
			$xLargeLayout: (
				font-size: pxToRem(70),
				line-height: math.div(78, 70)
			)
		)
	),
	headlight: (
		font-family: $fontFamilyMain,
		font-style: normal,
		font-size: pxToRem(30),
		font-weight: 200,
		line-height: math.div(35, 30),
		variants: (
			$smallLayout: (
				font-size: pxToRem(45),
				line-height: math.div(55, 45)
			),
			$xLargeLayout: (
				font-size: pxToRem(70),
				line-height: math.div(78, 70)
			)
		)
	),
	subhead: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(13),
		font-weight: bold,
		letter-spacing: 0.1rem,
		line-height: math.div(16, 13),
		variants: (
			$smallLayout: (
				font-size: pxToRem(14),
				line-height: math.div(18, 14)
			),
			$xLargeLayout: (
				font-size: pxToRem(18),
				line-height: math.div(22, 18)
			)
		)
	),
	quote: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(22),
		font-weight: normal,
		line-height: math.div(26, 22),
		variants: (
			$smallLayout: (
				font-size: pxToRem(28),
				line-height: math.div(36, 28)
			),
			$xLargeLayout: (
				font-size: pxToRem(40),
				line-height: (44 * 0.025)
			)
		)
	),
	header: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(22),
		font-weight: normal,
		line-height: math.div(26, 22),
		variants: (
			$smallLayout: (
				font-size: pxToRem(28),
				line-height: math.div(36, 28)
			),
			$xLargeLayout: (
				font-size: pxToRem(40),
				line-height: (44 * 0.025)
			)
		)
	),
	headerlight: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(22),
		font-weight: 200,
		line-height: math.div(26, 22),
		variants: (
			$smallLayout: (
				font-size: pxToRem(28),
				line-height: math.div(36, 28)
			),
			$xLargeLayout: (
				font-size: pxToRem(40),
				line-height: (44 * 0.025)
			)
		)
	),
	headersmaller: (
		font-family: $fontFamilyMain,
		font-weight: normal,
		font-size: pxToRem(22),
		line-height: math.div(26, 22),
		variants: (
			$smallLayout: (
				font-size: pxToRem(28),
				line-height: math.div(36, 28)
			),
			$xLargeLayout: (
				font-size: pxToRem(32),
				line-height: math.div(36, 32)
			)
		)
	),
	positiondescription: (
		font-size: pxToRem(16),
		line-height: math.div(20, 16),
		font-weight: normal,
		variants: (
			$smallLayout: (
				font-size: pxToRem(18),
				line-height: math.div(28, 18)
			),
			$xLargeLayout: (
				font-size: pxToRem(20),
				line-height: (28, 20)
			)
		)
	),
	headermediumlight: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(22),
		font-weight: 200,
		line-height: math.div(26, 22),
		variants: (
			$smallLayout: (
				font-size: pxToRem(28),
				line-height: math.div(36, 28)
			),
			$xLargeLayout: (
				font-size: pxToRem(28),
				line-height: math.div(36, 28)
			)
		)
	),
	headermedium: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(22),
		font-weight: normal,
		line-height: math.div(26, 22),
		variants: (
			$smallLayout: (
				font-size: pxToRem(28),
				line-height: math.div(36, 28)
			),
			$xLargeLayout: (
				font-size: pxToRem(28),
				line-height: math.div(36, 28)
			)
		)
	),
	subheader: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(15),
		font-weight: normal,
		line-height: math.div(20, 15),
		variants: (
			$smallLayout: (
				font-size: pxToRem(16),
				line-height: math.div(22, 16)
			),
			$xLargeLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18)
			)
		)
	),
	contentheader: (
		font-size: pxToRem(20),
		line-height: (28 * 0.05),
		font-weight: bold,
		variants: (
			$smallLayout: (
				font-size: pxToRem(22),
				line-height: math.div(30, 22)
			),
			$xLargeLayout: (
				font-size: pxToRem(28),
				line-height: math.div(36, 28)
			)
		)
	),
	caption: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(16),
		font-weight: normal,
		line-height: math.div(22, 16),
		variants: (
			$xLargeLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18)
			)
		)
	),
	h1: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(18),
		font-style: normal,
		font-weight: 500,
		line-height: math.div(22, 18),
		variants: (
			$smallLayout: (
				font-size: pxToRem(40),
				line-height: (48 * 0.025)
			),
			$xLargeLayout: (
				font-size: pxToRem(70),
				line-height: math.div(78, 70)
			)
		)
	),
	footermenu: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(15),
		font-weight: normal,
		line-height: math.div(20, 15),
		variants: (
			$smallLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18)
			),
			$mediumLayout: (
				font-size: pxToRem(16),
				line-height: math.div(22, 16)
			),
			$xLargeLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18)
			)
		)
	),
	submenu: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(18),
		font-weight: normal,
		line-height: math.div(20, 18),
		variants: (
			$smallLayout: (
				font-size: pxToRem(14),
				line-height: math.div(18, 14)
			),
			$largeLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18)
			),
			$xLargeLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18)
			)
		)
	),
	submenufooter: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(15),
		font-weight: normal,
		line-height: math.div(20, 15),
		variants: (
			$smallLayout: (
				font-size: pxToRem(14),
				line-height: math.div(18, 14)
			),
			$largeLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18)
			),
			$xLargeLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18)
			)
		)
	),
	smallbutton: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(14),
		font-weight: normal,
		line-height: math.div(14, 14),
		variants: (
			$smallLayout: (
				font-size: pxToRem(14),
				line-height: math.div(14, 14)
			)
		)
	),
	personposition: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(14),
		font-weight: bold,
		line-height: math.div(14, 14),
		variants: (
			$smallLayout: (
				font-size: pxToRem(14),
				line-height: math.div(18, 14)
			)
		)
	)
);
